import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1691f3a0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CDivider: require('@chakra-ui/vue').CDivider, CHeading: require('@chakra-ui/vue').CHeading, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CMenuButton: require('@chakra-ui/vue').CMenuButton, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox, CMenuDivider: require('@chakra-ui/vue').CMenuDivider, CButton: require('@chakra-ui/vue').CButton, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CGrid: require('@chakra-ui/vue').CGrid, CText: require('@chakra-ui/vue').CText, CSelect: require('@chakra-ui/vue').CSelect})
